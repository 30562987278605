import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import MainLayout from '../component/layout/MainLayout';
import Loadable from '../component/common/Loadable';

const HomePage = Loadable(lazy(() => import('../component/page/Home')))
const ForbiddenPage = Loadable(lazy(() => import("../component/page/Forbidden")));
const ProfileEditPage = Loadable(lazy(() => import("../component/page/User/ProfileEdit")));

const OrganizationNewPage = Loadable(lazy(() => import("../component/page/Organization/OrganizationNew")));
const OrganizationEditPage = Loadable(lazy(() => import("../component/page/Organization/OrganizationEdit")));
const CampaignListPage = Loadable(lazy(() => import("../component/page/Campaign/CampaignList")));
const ScrapeListPage = Loadable(lazy(() => import("../component/page/Scrape/ScrapeList")));
const SourceListPage = Loadable(lazy(() => import("../component/page/Source/SourceList")));
const TemplateListPage = Loadable(lazy(() => import("../component/page/Template/TemplateList")));

const MainRoutes = () => {
    const location = useLocation();

    return (
        <MainLayout>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={HomePage} />
                <Route path="/forbidden" component={ForbiddenPage} />
                <Route path="/profile" component={ProfileEditPage} />

                <Route path="/organization/:tag(new)" component={OrganizationNewPage} />
                <Route path="/organization/:id?" component={OrganizationEditPage} />
                <Route path="/campaign" component={CampaignListPage} />
                <Route path="/scrape" component={ScrapeListPage} />
                <Route path="/source" component={SourceListPage} />
                <Route path="/template" component={TemplateListPage} />
            </Switch>
        </MainLayout>
    );
};

export default MainRoutes;
