export const componentStyleOverrides = (theme: any) => {
    return {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&:last-of-type': {
                        borderRadius: 0
                    }
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingTop: '8px',
                    paddingBottom: '8px'
                },
                outlined: {
                    '&, &:hover': {
                        borderWidth: '2px',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                    }
                }
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    '&, &:hover': {
                        padding: '3px'
                    }
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&.autocomplete-large': {
                        minWidth: '150px'
                    },
                    '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
                        paddingTop: '2px',
                        paddingBottom: '3px'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /** checked not prop
                     *"&.Mui-checked": {
                     *    fontSize: "28px"
                     *}
                     */
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme.colors?.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme.colors?.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme.colors?.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme.colors?.grey200
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.colors?.bgPrimary,
                    background: theme.colors?.grey700
                }
            }
        }
    };
};
