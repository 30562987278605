import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Avatar, Chip, ChipProps, ListItem, ListItemIcon, ListItemText, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { LinkTarget, OverrideIcon } from '../../../../../../theme/type';
import clsx from 'clsx';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSharedState } from '../../../../../../state';
import { selectedNavItem } from '..';

//-----------------------|| SIDEBAR MENU LIST ITEMS ||-----------------------//

const useStyles = makeStyles()((theme) => {
    return {
        listIcon: {
            minWidth: '18px',
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        menuIcon: {
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        listCustomIcon: {
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        listCustomIconSub: {
            width: '6px',
            height: '6px',
            fontSize: '8px'
        },
        listCustomIconSubActive: {
            width: '8px',
            height: '8px',
            fontSize: '8px'
        },
        listItem: {
            marginBottom: '5px',
            alignItems: 'center'
        },
        listItemNoBack: {
            marginBottom: '5px',
            backgroundColor: 'transparent !important',
            paddingTop: '8px',
            paddingBottom: '8px',
            alignItems: 'flex-start'
        }
    };
});

type NavItemType = {
    id?: string;
    icon?: OverrideIcon;
    target?: boolean;
    external?: string;
    url?: string | undefined;
    type?: string;
    title?: string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    caption?: React.ReactNode | string;
    breadcrumbs?: boolean;
    disabled?: boolean;
    chip?: ChipProps;
    extraCss?: string
    onClick?: () => void
};

interface NavItemProps {
    item: NavItemType
    level: number
}

const NavItem = ({ item, level }: NavItemProps) => {
    const { classes } = useStyles();
    const [state, setState] = useSharedState();
    const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon />
    ) : (
        <FiberManualRecordIcon
            className={
                state.ui.isOpen.findIndex((id) => id === item?.id) > -1 ? classes.listCustomIconSubActive : classes.listCustomIconSub
            }
        />
    );

    /**
     *nav-dark doesnt exist on navType
     *navType can only be 'light' | 'dark'
     */

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component?: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = {};
    if(item.url){
        listItemProps.component= React.forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) 
    }
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = () => {
        if(item.onClick){
            item.onClick()
        } else {
            setState((prev) => ({ ...prev, ui: { ...prev.ui, isOpen: [item.id!] } }));
            matchesSM && setState((prev) => ({ ...prev, ui: { ...prev.ui, opened: false } }));
        }
        
    };

    // active menu item on page load
    const location = useLocation();
    React.useEffect(() => {
        if (selectedNavItem(location.pathname.toString()) === item.url) {
            setState((prev) => ({ ...prev, ui: { ...prev.ui, isOpen: [item.id!] } }));
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <ListItem
            {...listItemProps}
            disabled={item.disabled}
            className={clsx('menu-item menu-item-item', level > 1 && 'menu-item-sub', item.extraCss || '')}
            selected={state.ui.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={()=>itemHandler()}
            button
            style={{ marginLeft: (level-1) * 21 + 'px' }}
        >
            <ListItemIcon className={item?.icon ? "menu-item-icon" : "menu-item-icon-list"} style={{ minWidth: level > 1 ? 18 : 25 }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText className="menu-item-text" title={item.title?.toString()}
                primary={
                    <Typography noWrap variant='body2'>
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size || 'small'}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItem>
    );
};

export default NavItem;
