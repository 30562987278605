export enum Role {
  SystemAdmin=10,
  OrganizationAdmin = 100,
  User=110
}

export const hasUserRole = (userRoleIds: number[] | undefined, roles: Role[]) => {
  if (userRoleIds) {
    return roles.some(r => userRoleIds.includes(r));
  }
  return false;
}

export const hasUserRoleString = (userRoleIds: string[] | undefined, roles: Role[]) => {
  if (userRoleIds) {
    return roles.some(r => userRoleIds.some(rr => rr===r.toString()));
  }
  return false;
}

export const hasAnyRole = (userRoleIds: number[] | undefined) => {
  return userRoleIds?userRoleIds.length > 0:false;
}