import { Alert, Chip } from "@mui/material";
import { errorDetail } from "../../util/labelUtil";
import { useIntl } from "react-intl";

interface ErrorMessageProps {
  message?: string;
  type?: ErrorMessageType
}
export enum ErrorMessageType {
  Default,
  Chip
};

const ErrorMessage = ({ message, type }: ErrorMessageProps) => {
  const intl=useIntl()
  const label=errorDetail(message, intl);
  switch (type) {
    case ErrorMessageType.Chip:
      return <Chip color="error" label={label}></Chip>;
  }
  return <Alert icon={false} severity="error" className="error-message">
    {label}
  </Alert>;
};

export default ErrorMessage;