import theme_default from '../asset/scss/_themes_light.module.scss';
import theme_dark from '../asset/scss/_theme_dark.module.scss';

import { componentStyleOverrides } from './compStyleOverride';
import { themePalette } from './palette';
import { themeTypography } from './typography';

const themeOptions = (themeVal: string) => {
    let colors;
    switch (themeVal) {
        case 'dark':
            colors = theme_dark;
            break;
        default:
            colors = theme_default;
    }
    const options={
        colors: colors,
        fontFamily: `'Roboto', sans-serif`
    }
    return {
        palette: {
            ...themePalette(options),
            mode: themeVal
        },
        typography: themeTypography(options),
        components: componentStyleOverrides(options)
    }
}

export default themeOptions;