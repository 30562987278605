export const themePalette = (theme: any) => {
    return {
        primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain!,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight!,
            main: theme.colors?.orangeMain!,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain!,
            dark: theme.colors?.warningDark!
        },
        success: {
            light: theme.colors?.successLight,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            50: theme.colors?.grey50!,
            100: theme.colors?.grey100!,
            300: theme.colors?.grey300!,
            500: theme.colors?.grey500!,
            600: theme.colors?.grey600!,
            700: theme.colors?.grey700,
            900: theme.colors?.grey900
        },
        text: {
            primary: theme.colors?.textPrimary,
            secondary: theme.colors?.textSecondary,
            hint: theme.colors?.grey100!
        },
        background: {
            default: theme.colors?.bgPrimary,
            paper: theme.colors?.bgPaper,
            primary: theme.colors?.bgPrimary!,
            secondary: theme.colors?.bgSecondary,
            third: theme.colors?.bgThird
        }
    };
};
