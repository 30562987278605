import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

export function createApolloClient(authToken: string, dbUrl: string) {
  return new ApolloClient({
    link: new HttpLink({
      uri: dbUrl,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }),
    cache: new InMemoryCache({
      typePolicies: {
        organization_user: {
          fields: {
            user: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming;
              },
            },
          },
        },
        Query: {
          fields: {
            organization_by_pk: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming
              }
            },
            campaign_search: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming
              }
            },
            scrape_search: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming
              }
            },
            source_search: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming
              }
            },
            connection: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming
              }
            },
            template_search: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming
              }
            },
          }
        }
      }
    })
    /*defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        fetchPolicy: 'network-only'
      },
    },*/
  });
};

export const QueryFetchNetwork: any = {
  notifyOnNetworkStatusChange: true,
  fetchPolicy: 'network-only'
};