import { useEffect } from 'react';
import { useSharedState } from '../../../state';
import InputDropdown from '../../common/InputDropdown';
import { Locale } from '../../../locale';

const LocalizationSection = () => {
    const [state, setState] = useSharedState();

    useEffect(() => {
        localStorage.setItem('selected_locale', state.ui.locale);
    }, [state.ui.locale]);

    return <InputDropdown fullWidth options={state.locales} value={state.ui.locale || Locale.English}
    onChange={(e) => setState(prev => ({ ...prev, ui: { ...prev.ui, locale: e.target.value as string } }))}></InputDropdown>
};

export default LocalizationSection;
