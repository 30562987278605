export const themeTypography = (theme: any) => {
    return {
        fontFamily: theme?.fontFamily!,
        h6: {
            fontWeight: 500,
            fontSize: '0.75rem'
        },
        h5: {
            fontSize: '1.125rem',
            fontWeight: 500
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 600
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 600
        },
        h2: {
            fontSize: '1.125rem',
            fontWeight: 500
        },
        h1: {
            fontSize: '1.1875rem',
            fontWeight: 400
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 400
        },
        body1: {
            fontSize: '0.8125rem',
            fontWeight: 400
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400
        }
    };
};
