import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Loader from "./Loader";
import { createApolloClient } from "../../util/db";

export const BaseData = (props: { children: any }) => {
  const [token, setToken] = useState<string>('');
  
  const { isLoading, error, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const initAuth = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    initAuth();
  });

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  if (token.length > 0) {
    return (
      <ApolloProvider client={createApolloClient(token, process.env.REACT_APP_DB_URL || '')}>
        {props.children}
      </ApolloProvider>
    );
  }
  return <div></div>;
}

export default BaseData;