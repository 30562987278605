import { gql, useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./Loader";
import { Organization, User } from '../../util/db/graphql';
import { useSharedState } from "../../state";
import { Box, Grid } from "@mui/material";
import { Fragment, useEffect, useMemo } from "react";
import ErrorMessage, { ErrorMessageType } from "./ErrorMessage";
import { QueryFetchNetwork } from "../../util/db";
import ProfileSection from "../layout/MainLayout/ProfileSection";
import LocalizationSection from "../layout/MainLayout/LocalizationSection";
import Customization from "../layout/MainLayout/Customization";
import history from "../../util/history";
import { useIntl } from "react-intl";
import { ConfirmLeave, intlFormatDot, untitled } from "../../util/labelUtil";
import InputDropdown, { InputDropdownStyle } from "./InputDropdown";

const UserOptions = () => {
    const intl = useIntl()
    const [state, setState] = useSharedState();
    const {
        user
    } = useAuth0();
    //
    const [
        getUser,
        { loading, error, data }
    ] = useLazyQuery(gql`
        query ($id: Int!) {
            data: user_by_pk(id: $id) {
                id
                user_organizations(order_by: {organization_name: asc}) {
                    id
                    organization_name                    
                }
            }
        }  
        `, QueryFetchNetwork);
    useEffect(() => {
        user && getUser({
            variables: {
                id: user.hasura_user_id
            }
        });
        // eslint-disable-next-line
    }, [user]);
    //
    const [
        getOrganization,
        { loading: loading_org, error: error_org, data: data_org }
    ] = useLazyQuery(gql`
        query ($id: Int!, $user_id: Int!) {
          data:organization_by_pk(id: $id){
            id
            organization_name
            organization_user_roles(args: {_user_id: $user_id}){
                id
            }
          }
        }    
        `, QueryFetchNetwork);
    const getOrgDetail = (id?: number, userId?: number) => {
        getOrganization({
            variables: {
                id: id || state.organization?.id,
                user_id: userId || state.userId
            }
        })
    }
    useEffect(() => {
        (state.reload.organization || 0) > 0 && getOrgDetail();
        // eslint-disable-next-line
    }, [state.reload.organization]);
    useEffect(() => {
        if (!loading_org && !error_org && data_org) {
            setState((prev: any) => {
                const db: Organization= data_org.data;
                return {
                    ...prev,
                    organization: {
                        ...db,
                        role_ids: (db.organization_user_roles || []).map(r => r.id)
                    },
                    reload: {
                        ...prev.reload,
                        organization: 0
                    },
                };
            });
        }
        // eslint-disable-next-line
    }, [loading_org, error_org, data_org]);
    //
    const handleChangeOrganization = (match: any, url?: string, userId?: number) => {
        match && getOrgDetail(match.id, userId);
        localStorage.setItem('selected_organization_id', match?.id || null);
        if (match && url) {
            history.push(url);
        }
    }
    useEffect(() => {
        if (!loading && !error && data) {
            const u: User = data.data;
            const orgs =u.user_organizations;
            setState((prev) => {
                return {
                    ...prev,
                    userId: u.id,
                    organizations: orgs as any
                }
            });
            if (!state.organization?.id) {
                if (orgs && orgs.length > 0) {
                    const selected = localStorage.getItem('selected_organization_id');
                    let match = orgs.find(r => r.id.toString() === selected);
                    if (!match) {
                        match = orgs[0];
                    }
                    handleChangeOrganization(match, undefined, u.id);
                } else {
                    handleChangeOrganization(null);
                }
            }
        }
        // eslint-disable-next-line
    }, [loading, error, data]);
    
    useEffect(() => {
        if (state.forceOrganizationId && state.userId) {
            const match = state.organizations.find(r => r.id === state.forceOrganizationId.value);
            if (match) {
                handleChangeOrganization(match, state.forceOrganizationId.url);
                setState((prev) => ({
                    ...prev,
                    forceOrganizationId: {
                        value: null
                    }
                }));
            }
        }
        // eslint-disable-next-line
    }, [state.forceOrganizationId, state.userId]);
    //
    const onChangeOrganization = (e: any) => {
        const value = e.target.value as number;
        if (!state.hasChanges) {
            const match = state.organizations.find(r => r.id === value);
            handleChangeOrganization(match);
        } else if (window.confirm(intl.formatMessage({ id: ConfirmLeave }))) {
            setState((prev) => ({ ...prev, hasChanges: false, forceOrganizationId: { value: value } }));
        }
    };
    //
    const orgOptions = useMemo(() => state.organizations.map(r => ({ id: r.id, label: untitled(r.organization_name) })),
        [state.organizations])
    return <Fragment>
        {(loading || loading_org) && <Loader />}
        <Box className="sidebar-user">            
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item><ProfileSection /></Grid>
                <Grid item flexGrow={1} className="sidebar-user-org">
                    {state.hasChanges?'':''}
                    {(error || error_org) ? <ErrorMessage type={ErrorMessageType.Chip} /> :
                        <InputDropdown fullWidth value={state.organization?.id || ''} label={intl.formatMessage({id: 'organization'})}
                            options={orgOptions} styleName={InputDropdownStyle.NoBorder}
                            onChange={onChangeOrganization}
                            extras={[
                                { label: intlFormatDot(intl,'create-new-organization'), link: '/organization/new' },
                            ]}
                        />}
                </Grid>
            </Grid>
        </Box>
        <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item xs={6}><LocalizationSection /></Grid>
            <Grid item xs={6}><Customization /></Grid>
        </Grid>
    </Fragment>;
};

export default UserOptions;
