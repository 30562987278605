import { FormControl, InputLabel, ListItem, ListItemText, MenuItem, Select, SelectProps } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectOptionAny } from "../../util/type";
import { Link } from 'react-router-dom';

export enum InputDropdownStyle {
  Default=0,
  NoBorder=1
}
type InputDropdownProps = SelectProps & {
  options: SelectOptionAny<string | number>[]
  fullWidth?: boolean
  extras?: { link: string, label: string }[]
  label?: string
  styleName?: InputDropdownStyle
}

const InputDropdown = ({ options, fullWidth, extras, label, styleName, ...others }: InputDropdownProps) => {
  return (
    <FormControl variant="standard" className={`select-dropdown select-dropdown-${styleName}`} fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select IconComponent={ExpandMoreIcon} {...others}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
        {extras && extras.map((r, idx) => {
          return <ListItem key={idx}
            button
            component={Link}
            to={r.link}
          >
            <ListItemText
              primary={r.label}
            />
          </ListItem>
        })}
      </Select>
    </FormControl>
  );
};

export default InputDropdown;
