import { IconBaseProps } from ".";
import IconBase from "./IconBase";

const IconTemplate = (props: IconBaseProps) => {
    return <IconBase viewBox="0 0 20 20" className="fill-default" {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
            <path d="M7 3.27588C6.44772 3.27588 6 3.72359 6 4.27588C6 4.82816 6.44772 5.27588 7 5.27588H13C13.5523 5.27588 14 4.82816 14 4.27588C14 3.72359 13.5523 3.27588 13 3.27588H7Z" />
            <path d="M4 7.27588C4 6.72359 4.44772 6.27588 5 6.27588H15C15.5523 6.27588 16 6.72359 16 7.27588C16 7.82816 15.5523 8.27588 15 8.27588H5C4.44772 8.27588 4 7.82816 4 7.27588Z" />
            <path d="M2 11.2759C2 10.1713 2.89543 9.27588 4 9.27588H16C17.1046 9.27588 18 10.1713 18 11.2759V15.2759C18 16.3804 17.1046 17.2759 16 17.2759H4C2.89543 17.2759 2 16.3804 2 15.2759V11.2759Z" />
        </svg>
    </IconBase>
};

export default IconTemplate;
