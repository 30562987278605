import { IntlShape } from "react-intl";
import { SelectOption, SelectOptionAny } from "./type";
import { Locale } from "../locale";

export const ConfirmDelete = 'message.confirm-delete';
export const ConfirmDeactivate = 'message.confirm-deactivate';
export const ConfirmLeave = 'message.confirm-leave';

export const ErrorGeneral = 'message.error';

export const InfoNoChanges = 'message.no-changes';

export const SuccessDeleted = 'message.deleted';
export const SuccessSaved = 'message.saved';
export const SuccessActivated = 'message.activated';
export const SuccessDeactivated = 'message.deactivated';

export function copyOf(intl: IntlShape, val: string) {
    return intl.formatMessage({ id: 'copy-of-p' }, { p0: val || '' });
}

export const errorDetail = (err: any, intl: IntlShape) => {
    return err?.message || err || intl.formatMessage({ id: ErrorGeneral });
}

export const pdfContent = (content: any) => {
    return `
    <html>
        <head>
            <style>
                .pdf-content {font-size: 0.875rem;
                line-height: 1.334em;color: #202736;padding: 24px;}
                .MuiGrid-container {display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;width: calc(100% + 16px);margin-left: -16px;}
                .MuiGrid-item {padding-top: 16px;
                padding-left: 16px;-webkit-flex-basis: 50%;
                -ms-flex-preferred-size: 50%;
                flex-basis: 50%;
                -webkit-box-flex: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                max-width: 50%;}            
                table {width: 100%;}
                th, td {border-bottom: 1px solid #EEF0F3;
                padding: 8px 15px;text-align:left;}
                th, tfoot td {
                color: #B1B9CA;
                font-weight: 500;}
                .MuiTableCell-alignRight {text-align:right;}
                .print-verification-org {font-weight: bold;}
                .print-title {font-size: large;
                font-weight: bold;
                margin-bottom: 10px;}
            </style>
        </head>
        <body>
        ${content}
        </body>
    </html>
    `;
}

export const untitled = (val: any, defaultVal = 'Untitled') => {
    return (val && val !== '') ? val : defaultVal;
}

export const untitledOptions = <T extends SelectOption,>(l: T[]) => {
    return l.map(r => {
        return {
            ...r,
            label: untitled(r.label)
        }
    });
}

export const labelOption = <T>(id: T | null, options: SelectOptionAny<T>[], intl?: IntlShape) => {
    const v = options.find(r => r.id === id)?.label;
    if (v) {
        return intl ? intl.formatMessage({ id: v }) : v
    }
    return null
}

export function label(id: any, label: any) {
    return `${id} - ${label || ''}`;
}

export function lowerFirstLetter(val: string) {
    return val.charAt(0).toLowerCase() + val.slice(1);
}

export function upperFirstLetter(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

export function breadcrumbLabel(val: string) {
    return val!! ? ` / ${val}` : '';
}

export enum LabelType {
    Create = "create-new-p",
    Edit = "edit-p",
    View = "view-p"
}

export function dataLabel(intl: IntlShape, type: LabelType, field: string, nr?: string | number | null) {
    let id = 'create-new-p';
    switch (type) {
        case LabelType.Edit:
            id = nr ? 'edit-p-nr' : 'edit-p';
            break;
        case LabelType.View:
            id = nr ? 'view-p-nr' : 'view-p';
            break;
    }
    return intl.formatMessage({
        id
    }, {
        p0: lowerFirstLetter(intl.formatMessage({ id: field })),
        ...(nr ? { p1: nr } : null)
    })
}

export const intlFormatNormal = (intl: IntlShape, id: string, field: string) => intl.formatMessage({ id }, { p0: intl.formatMessage({ id: field }) })

export const intlFormatLowerFirst = (intl: IntlShape, id: string, field: string) => intl.formatMessage({ id }, { p0: lowerFirstLetter(intl.formatMessage({ id: field })) })

export const intlFormatDot = (intl: IntlShape, id: string) => `${intl.formatMessage({ id })}..`

export const intlTranslated = (intl: IntlShape, field: string) => intlFormatLowerFirst(intl, "translated-p", field)

export const optionsVal = <T>(intl: IntlShape, val: SelectOptionAny<T>[]): SelectOptionAny<T>[] => val.map(r => ({ ...r, label: intl.formatMessage({ id: r.label }) }))

export const localeLabel = (translated: any, name: string, locale: string=Locale.English): string => {
    switch (locale) {
        case Locale.Swedish:
            return translated?(translated[locale] || translated[Locale.SwedishAlt] || localeLabel(translated,name)):name
        default:
            return translated?(translated[Locale.English] || translated[Locale.EnglishAlt] || name):name
    }
}