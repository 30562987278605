import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import UserOptions from '../../../common/UserOptions';
import { Fragment, useEffect, useState } from 'react';
import { ChevronRightOutlined } from '@mui/icons-material';

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    // Handle left drawer
    const [drawerOpened, setDrawerOpened] = useState(false);
    const handleDrawerToggle = () => {
        setDrawerOpened((prev) => (!prev));
    };

    useEffect(() => {
        setDrawerOpened(!matchDownMd);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    //
    return <Fragment>
        <nav className={clsx('sidebar', !drawerOpened && 'mini')}>
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpened}
                onClose={handleDrawerToggle}
                classes={{
                    paper: `sidebar-paper ${drawerOpened ? '' : 'mini'}`
                }}
                ModalProps={{ keepMounted: true }}
            >
                <Box display="flex" flexDirection="column" className="sidebar-content">
                    <Box className="sidebar-top">
                        <LogoSection />
                        <UserOptions />
                    </Box>

                    <PerfectScrollbar component="div">
                        <MenuList />
                    </PerfectScrollbar>
                </Box>
            </Drawer>
        </nav>
        {!drawerOpened && <IconButton size='small' className={clsx('btn-action menu-button')} onClick={handleDrawerToggle}>
            <ChevronRightOutlined />
        </IconButton>}
    </Fragment>
};

export default Sidebar;
