import NavGroup from './NavGroup';
import { Box } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';
import IconCampaign from '../../../../icon/IconCampaign';
import IconTemplate from '../../../../icon/IconTemplate';
import IconCompany from '../../../../icon/IconCompany';
import IconProfile from '../../../../icon/IconProfile';
import { Role, hasUserRole } from '../../../../../util/roleUtil';
import { useSharedState } from '../../../../../state';
import { LogoutOutlined, ScatterPlotOutlined, SourceOutlined } from '@mui/icons-material';
import { errorDetail } from '../../../../../util/labelUtil';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';

const MenuList = () => {
    const intl = useIntl();
    const [state,] = useSharedState()
    const isOrgAdmin = useMemo(() => hasUserRole(state.organization?.role_ids, [Role.OrganizationAdmin]),
        [state.organization?.role_ids]);
    const isUser = useMemo(() => hasUserRole(state.organization?.role_ids, [Role.User]),
        [state.organization?.role_ids]);
    const menuitems = useMemo(() => {
        const children = []
        if (isOrgAdmin) {
            children.push({
                id: 'scrape',
                title: intl.formatMessage({ id: 'scrape-definitions' }),
                type: 'item',
                url: '/scrape',
                icon: ScatterPlotOutlined
            },
                {
                    id: 'source',
                    title: intl.formatMessage({ id: 'sources' }),
                    type: 'item',
                    url: '/source',
                    icon: SourceOutlined
                })
        }
        children.push({
            id: 'template',
            title: intl.formatMessage({ id: 'templates' }),
            type: 'item',
            url: '/template',
            icon: IconTemplate
        },
            {
                id: 'campaign',
                title: intl.formatMessage({ id: 'campaigns' }),
                type: 'item',
                url: '/campaign',
                icon: IconCampaign
            })
        return [
            {
                id: 'top',
                type: 'group',
                children
            }];
    }, [intl, isOrgAdmin]);

    const { logout } = useAuth0();
    const other = useMemo(() => {
        const children = [];
        if (isOrgAdmin || isUser) {
            children.push({
                id: 'organization',
                title: intl.formatMessage({ id: 'organization-settings' }),
                type: 'item',
                url: '/organization',
                icon: IconCompany
            })
        }
        children.push({
            id: 'profile',
            title: intl.formatMessage({ id: 'personal-profile' }),
            type: 'item',
            url: '/profile',
            icon: IconProfile
        }, {
            id: 'logout',
            title: intl.formatMessage({ id: 'log-out' }),
            type: 'item',
            onClick: () => {
                try {
                    logout({
                        returnTo: window.location.origin
                    });
                } catch (err) {
                    toast.error(errorDetail(err, intl));
                }
            },
            icon: LogoutOutlined
        })
        return {
            id: 'other',
            type: 'group',
            children
        };
    }, [intl, isOrgAdmin, isUser, logout]);

    return <Fragment>
        <Box display="flex" flexDirection="column" className="menu-container">
            <Box flexGrow={1}>
                {menuitems.map((item) => {
                    return <NavGroup key={item.id} item={item} />;
                })}
            </Box>
            {other && <NavGroup item={other} />}
        </Box>
    </Fragment>;
};

export default MenuList;


export const selectedNavItem = (url: string, fake = false) => {
    if (url.startsWith('/organization/')) {
        return '/organization';
    } else if (url.startsWith('/campaign/')) {
        return '/campaign';
    } else if (url.startsWith('/template/')) {
        return '/template';
    }
    return url;
}