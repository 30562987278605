import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import NavItem from '../NavItem';
import clsx from 'clsx';
import { FiberManualRecord, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { NavGroupProps } from '../NavGroup';
import { useLocation } from 'react-router-dom';
import { selectedNavItem } from '..';

// style constant
const useStyles = makeStyles()((theme) => {
    return {
        menuIcon: {
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        listIcon: {
            minWidth: '18px',
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        listCustomIcon: {
            marginTop: 'auto',
            marginBottom: 'auto',
            fontSize: '1.3rem'
        },
        listItemTypography: {
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        listCustomIconSub: {
            fontSize: '8px',
            width: '6px',
            height: '6px'
        },
        listCustomIconSubActive: {
            fontSize: '8px',
            width: '8px',
            height: '8px'
        },
        listItem: {
            marginBottom: '5px',
            alignItems: 'flex-start'
        },
        listItemNoBack: {
            marginBottom: '5px',
            backgroundColor: 'transparent !important',
            paddingTop: '8px',
            paddingBottom: '8px',
            alignItems: 'flex-start'
        },
        collapseWrapper: {
            position: 'relative',
            '&:after': {
                content: "''",
                position: 'absolute',
                left: '32px',
                top: 0,
                height: '100%',
                width: '1px',
                opacity: theme.palette.mode === 'dark' ? 0.2 : 1
            }
        }
    };
});

//-----------------------|| SIDEBAR MENU LIST COLLAPSE ITEMS ||-----------------------//

export interface NavCollapseProps {
    menu: NavGroupProps['item'];
    level: number;
}

const NavCollapse = ({ menu, level }: NavCollapseProps) => {
    const { classes } = useStyles();

    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<string | null | undefined>(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // active menu item on page load
    const location = useLocation();
    React.useEffect(() => {
        findMenuItem(menu, selectedNavItem(location.pathname.toString(), true)).then(m => {
            if (m) {
                !open && handleClick();
            }
        });
        // eslint-disable-next-line
    }, [location.pathname]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon!;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} className={classes.listCustomIcon} />
    ) : (
        <FiberManualRecord
            className={selected === menu.id ? classes.listCustomIconSubActive : classes.listCustomIconSub}
        />
    );

    let menuIconClass = !menu.icon ? classes.listIcon : classes.menuIcon;

    return (
        <React.Fragment>
            <ListItem
                className={clsx('menu-item', 'menu-item-collapse', classes.listItem)}
                selected={selected === menu.id}
                button
                onClick={handleClick}
                style={{ paddingLeft: level * 21 + 'px' }}
            >
                <ListItemIcon className={`${menu.icon ? "menu-item-icon" : "menu-item-icon-list"} ${menuIconClass}`} style={{ minWidth: level > 1 ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText className='menu-item-text'
                    primary={
                        <Typography variant='body1' color="inherit" className={`menu-item-text ${classes.listItemTypography}`}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <KeyboardArrowUpOutlined />
                ) : (
                    <KeyboardArrowDownOutlined />
                )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className='menu-item-collapse-items'>
                <List component="div" disablePadding className={classes.collapseWrapper}>
                    {menus}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export default NavCollapse;

async function findMenuItem(menu: NavGroupProps['item'], url: string): Promise<string | null> {
    const m = menu.children!.find((r:any) => r.type === 'item' && r.url === url);
    if (m) {
        return m.id!;
    } else {
        return await Promise.any(menu.children!.filter(r => r.type === 'collapse').map(async (r) => {
            return await findMenuItem(r, url);
        })).catch(r => {
            return null;
        });
    }
}
