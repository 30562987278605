import React from 'react';
import { Divider, List, Typography } from '@mui/material';
import NavItem from './../NavItem';
import { OverrideIcon } from '../../../../../../theme/type';
import NavCollapse from '../NavCollapse';

//-----------------------|| SIDEBAR MENU LIST GROUP ||-----------------------//

export interface NavGroupProps {
    item: {
        id?: string;
        type?: string;
        icon?: OverrideIcon;
        children?: NavGroupProps['item'][];
        title?: string;
        caption?: React.ReactNode | string;
        color?: 'primary' | 'secondary' | 'default' | undefined;
        divider?: boolean;
    };
}

const NavGroup = ({ item }: NavGroupProps) => {
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <React.Fragment>
            <List className="menu"
                subheader={
                    item.title && (
                        <Typography variant="caption" className="menu-group-title" display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            {item.divider && <Divider className="menu-divider" />}
        </React.Fragment>
    );
};

export default NavGroup;
