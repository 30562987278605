import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './Sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';
import BaseData from '../../common/BaseData';

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
    
    const {
        isAuthenticated
    } = useAuth0();

    return (
        <Box display="flex">
            <CssBaseline />
            {/* header */}
            <div><Toaster /></div>
            {/* drawer */}
            {isAuthenticated ? <BaseData>
                <Sidebar />
            </BaseData> : null}

            {/* main content */}
            <Box component="main" className="main-content">
                {children}
            </Box>
        </Box>
    );
};

export default MainLayout;
