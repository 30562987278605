import { useMemo } from 'react';

import {
    Avatar
} from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

const ProfileSection = () => {
    const {
        user
    } = useAuth0();

    const profilePicture = useMemo(() => {
        return user?.picture
        // eslint-disable-next-line
    }, [])
    return <Avatar className='medium-avatar'
        src={profilePicture}
        color="inherit" variant='rounded'
    />
};

export default ProfileSection;
