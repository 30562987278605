import history from "./util/history";
import { Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './route';
import themeOptions from './theme';
import NavigationScroll from './component/layout/NavigationScroll';
import { Auth0Provider } from "@auth0/auth0-react";
import Locales from "./component/common/Locales";
import { useSharedState } from "./state";
import { useMemo } from "react";
import { enUS, svSE } from '@mui/material/locale';
import './asset/scss/style.scss';

const App = () => {
  const [state,]=useSharedState();
  const theme = useMemo(() => {
    return extendTheme(themeOptions(state.ui.theme), state.ui.locale==="sv"?svSE:enUS);
  },[state.ui.locale, state.ui.theme])
  const onRedirectCallback = (appState: any) => {
    history.push(
      appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };

  // Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
  // for a full list of the available properties on the provider

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    redirectUri: window.location.origin,
    onRedirectCallback,
  };
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <Locales>
          <NavigationScroll>
            <Auth0Provider {...providerConfig}>
              <>
                <Routes />
              </>
            </Auth0Provider>
          </NavigationScroll>
        </Locales>
      </CssVarsProvider>
    </StyledEngineProvider>
  );
};

export default App;
