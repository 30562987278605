import { useState } from 'react';
import { createContainer } from 'react-tracked';
import { DefaultLocales, Locale } from '../locale';
import { Organization } from '../util/db/graphql';
import { SelectOptionAny } from '../util/type';

type StateOrganization = Organization & {
  role_ids: number[]
};

export type StateProps={
  locales: SelectOptionAny<string>[]
  rpps: number[]
  rpp_default: number
  rpps_search: number[]
  rpp_default_search: number
  //
  ui: {
    isOpen: string[]
    locale: string
    theme: string
  }
  hasChanges: boolean
  userId: number | null
  organizations: StateOrganization[]
  organization: StateOrganization | null
  forceOrganizationId: {
    value: number | null
    url?: string
    isNew?: boolean
  }
  reload: {
    organization: number
  },
}
const initialState: StateProps = {
  locales: DefaultLocales,
  rpps: [20, 50, 100],
  rpp_default: 100,
  rpps_search: [10],
  rpp_default_search: 10,
  //
  ui: {
    isOpen: [], //for active default menu,
    locale: localStorage.getItem('selected_locale') || Locale.English,
    theme: localStorage.getItem('selected_theme') || 'light',
  },
  userId: null,
  hasChanges: false,
  organizations: [],
  organization: null,
  forceOrganizationId: {
    value: null
  },
  reload: {
    organization: 0
  },
};

const useMyState = () => useState(initialState);

export const {
  Provider: SharedStateProvider,
  useTracked: useSharedState,
} = createContainer(useMyState);