import { SvgIcon } from "@mui/material"
import { ReactNode } from "react";
import { IconBaseProps } from ".";

const IconBase = ({children,className,baseClass,...others}: IconBaseProps & {
    children: ReactNode
}) => {
    return <SvgIcon className={`${baseClass || 'icon-custom'} ${className || 'stroke-default'}`} {...others}>
        {children}
    </SvgIcon>
};

export default IconBase;
