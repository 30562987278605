import { useIntl } from 'react-intl';
import InputDropdown from '../../common/InputDropdown';
import { useSharedState } from '../../../state';
import { useEffect } from 'react';

const Customization = () => {
    const intl = useIntl()
    const [state, setState] = useSharedState()
    const themeOptions = [
        { id: "light", label: intl.formatMessage({ id: "light" }) },
        { id: "dark", label: intl.formatMessage({ id: "dark" }) }]
        //
        useEffect(() => {
            localStorage.setItem('selected_theme', state.ui.theme);
        }, [state.ui.theme]);
    return <InputDropdown fullWidth options={themeOptions} value={state.ui.theme}
        onChange={(e) => setState(prev => ({ ...prev, ui: { ...prev.ui, theme: e.target.value as string } }))}></InputDropdown>
};

export default Customization;
