import { Typography } from "@mui/material";

const Logo = () => {
    return <Typography variant="h1" className="logo">Promo <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 26 29" fill="none">
        <path d="M14.8553 2L15.1077 1.50784C15.3611 1.01342 15.9411 0.693803 16.585 0.693803H16.5852C16.819 0.693803 17.0078 0.532188 16.9998 0.335161C16.9923 0.145451 16.797 -0.00116739 16.5698 7.00555e-06C15.5994 0.00506593 14.7272 0.488465 14.3448 1.23475L14 1.90722H14.1401C14.3893 1.90722 14.6295 1.93983 14.8553 2Z" fill="#5A38F2" />
        <path d="M10.4148 0.693803H10.415C11.0589 0.693803 11.6389 1.01342 11.8923 1.50784L12.1447 2C12.3705 1.93983 12.6107 1.90722 12.8599 1.90722H13L12.6552 1.23475C12.2728 0.488465 11.4006 0.00506593 10.4302 7.00555e-06C10.203 -0.00116739 10.0077 0.145451 10.0002 0.335161C9.99225 0.532188 10.1809 0.693803 10.4148 0.693803Z" fill="#5A38F2" />
        <path d="M11.5584 4.99992L14.4416 5C14.7804 4.79714 15 4.47408 15 4.11083C15 3.49831 14.3756 3 13.6081 3H12.3919C11.6244 3 11 3.49831 11 4.11083C11 4.47408 11.2196 4.79714 11.5584 4.99992Z" fill="#FFCF40" />
        <path d="M10.2186 26H15.7814C15.8976 25.6776 15.9715 25.3419 16 25H10C10.0286 25.3419 10.1024 25.6776 10.2186 26Z" fill="#FFCF40" />
        <path d="M10 23H16V24H10V23Z" fill="#FFCF40" />
        <path d="M11.3708 27.4655L12.6935 28.8711C12.7705 28.953 12.8824 29 13 29C13.1175 29 13.2294 28.9529 13.3065 28.8711L14.6292 27.4655C14.767 27.3189 14.8907 27.1631 15 27H11C11.1092 27.1632 11.233 27.3189 11.3708 27.4655Z" fill="#FFCF40" />
        <path d="M14.6292 21.5345L13.3065 20.1289C13.2295 20.047 13.1176 20 13 20C12.8825 20 12.7706 20.0471 12.6935 20.1289L11.3708 21.5345C11.233 21.6811 11.1093 21.8369 11 22H15C14.8908 21.8368 14.767 21.6811 14.6292 21.5345Z" fill="#FFCF40" />
        <path d="M10.1577 9.64619C10.2078 7.01417 10.8351 4.92175 13.7479 4.94158C16.549 4.96065 17.2068 7.13634 17.1567 9.76835C17.1066 12.4004 14.4604 17.9557 13.5005 17.9389C12.5406 17.9222 10.1076 12.2782 10.1577 9.64619Z" fill="#FFCF40" />
        <path d="M15 18.9221C17.6624 15.3038 18.4199 12.6575 18.4767 7C24.9396 12.1135 26.4504 15.8841 25.8938 24C20.9366 22.5893 18.6004 21.4377 15 18.9221Z" fill="#5A38F2" />
        <path d="M11 18.9221C8.33762 15.3038 7.58007 12.6575 7.52325 7C1.06036 12.1135 -0.450402 15.8841 0.106189 24C5.0634 22.5893 7.39955 21.4377 11 18.9221Z" fill="#5A38F2" />
    </svg>Bees</Typography>;
};

export default Logo;
