import { IconBaseProps } from ".";
import IconBase from "./IconBase";

const IconCampaign = (props: IconBaseProps) => {
    return <IconBase viewBox="0 0 20 20" className="fill-default" {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4892 3.44682C11.1102 1.88557 8.8898 1.88556 8.51078 3.44682C8.26594 4.45537 7.11045 4.93399 6.22416 4.39397C4.85218 3.558 3.28212 5.12806 4.11809 6.50004C4.65811 7.38633 4.17949 8.54181 3.17094 8.78666C1.60969 9.16568 1.60969 11.3861 3.17094 11.7651C4.17949 12.0099 4.65811 13.1654 4.11809 14.0517C3.28212 15.4237 4.85218 16.9938 6.22417 16.1578C7.11045 15.6178 8.26594 16.0964 8.51078 17.1049C8.8898 18.6662 11.1102 18.6662 11.4892 17.1049C11.7341 16.0964 12.8896 15.6178 13.7758 16.1578C15.1478 16.9938 16.7179 15.4237 15.8819 14.0517C15.3419 13.1654 15.8205 12.0099 16.8291 11.7651C18.3903 11.3861 18.3903 9.16568 16.8291 8.78666C15.8205 8.54181 15.3419 7.38633 15.8819 6.50004C16.7179 5.12806 15.1478 3.558 13.7758 4.39397C12.8896 4.93399 11.7341 4.45537 11.4892 3.44682ZM10 13.2759C11.6569 13.2759 13 11.9327 13 10.2759C13 8.61902 11.6569 7.27588 10 7.27588C8.34315 7.27588 7 8.61902 7 10.2759C7 11.9327 8.34315 13.2759 10 13.2759Z" />
        </svg>
    </IconBase>
};

export default IconCampaign;
